import { Grid } from '@mui/joy'
import { useFormContext } from 'react-hook-form'
import { InspectionReasonCodeEnum } from '~/shared/config/enums'
import { TMS_DOMAIN } from '~/shared/config/env'
import { LinkDecorator } from '~/shared/ui/Buttons'
import { FormTitle, Switch, TextareaInput, TextInput } from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'

type MainProps = {
  carId?: UniqueId
  driverExtId?: UniqueId
  loading: boolean
}

export function Main({ carId, driverExtId, loading }: MainProps) {
  const { watch } = useFormContext()

  const reasonCode = watch('reasonCode')
  const isAfterAccident = reasonCode === InspectionReasonCodeEnum.AFTER_ACCIDENT

  return (
    <Container>
      <FormTitle>Основная информация</FormTitle>
      <Grid container spacing={2.5}>
        <Grid xs={6}>
          <TextInput
            label='Гос. номер'
            name='plateNumber'
            readOnly
            skeletonShow={loading}
            endDecorator={
              carId && (
                <LinkDecorator
                  tooltipText='Перейти в карточку автомобиля'
                  href={`${TMS_DOMAIN}cars/${carId}`}
                />
              )
            }
          />
        </Grid>
        <Grid xs={4.4}>
          <TextInput
            label='Водитель'
            name='driverFullName'
            readOnly
            skeletonShow={loading}
            endDecorator={
              driverExtId && (
                <LinkDecorator
                  tooltipText='Перейти в карточку водителя'
                  href={`${TMS_DOMAIN}drivers/${driverExtId}`}
                />
              )
            }
          />
        </Grid>
        <Grid xs={1.6}>
          <Switch
            label='Участвовал'
            name='withDriverParticipation'
            readOnly
            skeletonShow={loading}
            formControlProps={{ sx: { marginTop: '40px' } }}
          />
        </Grid>

        <Grid xs={3}>
          <TextInput label='VIN' name='vin' readOnly skeletonShow={loading} />
        </Grid>
        <Grid xs={3}>
          <TextInput
            label='Модель'
            name='carBrandModel'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Механик'
            name='responsible'
            readOnly
            skeletonShow={loading}
          />
        </Grid>

        <Grid xs={4.4}>
          <TextInput
            label='Причина осмотра'
            name='reason'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={1.6}>
          <Switch
            label='По записи'
            name='isPlanned'
            readOnly
            skeletonShow={loading}
            formControlProps={{ sx: { marginTop: '40px' } }}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Длительность осмотра (мм:сс)'
            name='duration'
            readOnly
            skeletonShow={loading}
          />
        </Grid>

        <Grid xs={6}>
          <TextareaInput
            label='Комментарий'
            name='comment'
            minRows={4}
            maxRows={4}
            readOnly
            skeletonShow={loading}
            sx={{
              height: '138px',
            }}
          />
        </Grid>
        <Grid xs={6}>
          {isAfterAccident && (
            <TextInput
              label='Виновник происшествия'
              name='incidentResponsible'
              readOnly
              skeletonShow={loading}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  )
}
